import { Button } from "@3beehivetech/components";
import styled from "styled-components";
import { 
  BP, 
  Colors, 
  Rem,
} from "../../../commons/Theme";
import { StyledTitleHero } from "../../../commons/Typography";

export const StyledWrapperBackground = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 20.57%,
      rgba(0, 0, 0, 0) 50%
    ),
    linear-gradient(0deg, rgba(247, 247, 247, 0.3), rgba(51, 51, 51, 0.45)), url(${({ heroImage }) => heroImage});
  background-size: cover;
  background-position: center;
  color: ${Colors.white};
`;

export const StyledWrapperContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3rem;
  height: 100%;
  width: 100%;
  max-width: 93rem;
  padding: 0 1.25rem;
  @media (${BP.tablet}) {
    align-items: flex-start;
    max-width: 1248px;
    padding: 0 2rem;
    margin: 0 auto;
  }
`;

export const StyledTitle = styled(StyledTitleHero)`
  font-size: ${Rem(40)} !important;
  text-align: center;
  @media (${BP.tablet}) {
    font-size: ${Rem(80)} !important;
    line-height: ${Rem(85)} !important;
    text-align: left;
  }
`;

export const StyledWrapperButton = styled.div`
  z-index: 3;
  width: 100%;
  @media (${BP.tablet}) {
    width: unset;
  }
`;

export const StyledHeroCta = styled(Button)`
  display: flex;
  padding: ${Rem(0)} !important;
  width: 100%;
  &:hover {
    a {
      color: ${Colors.yellow};
    }  
  }
  a {
    width: 100%;
    text-decoration: none;
    color: ${Colors.white};
    padding: ${Rem(16)} ${Rem(0)};
  }
  @media (${BP.tablet}) {
    a {
      padding: ${Rem(16)} ${Rem(89)};
    }
  }
`;

export const StyledGradient = styled.div`
  position: absolute;
  bottom: 0px;
  background: linear-gradient(0deg,#ffffff, transparent);
  height: 200px;
  width: 100%;
`;
